
import { computed, defineComponent, reactive } from 'vue';
import { useForm } from 'ant-design-vue/es/form';
import { message, notification } from 'ant-design-vue';
import { MailOutlined } from '@ant-design/icons-vue';
import { getSmsCaptcha } from '@/api/user/login';
import { postRegister } from '@/api/user/register';
import { AxiosError } from 'axios';
import { useRouter } from 'vue-router';

const levelNames: Record<number, string> = {
  0: '低',
  1: '低',
  2: '中',
  3: '强',
};
const levelClass: Record<number, string> = {
  0: 'error',
  1: 'error',
  2: 'warning',
  3: 'success',
};
const levelColor: Record<number, string> = {
  0: '#ff0000',
  1: '#ff0000',
  2: '#ff7e05',
  3: '#52c41a',
};

export default defineComponent({
  setup() {
    const router = useRouter();
    const state = reactive({
      time: 60,
      smsSendBtn: false,
      passwordLevel: 0,
      passwordLevelChecked: false,
      percent: 10,
      progressColor: '#FF0000',
      registerBtn: false,
    });
    const modelRef = reactive({
      email: '',
      password: '',
      password2: '',
      mobile: '',
      captcha: '',
    });
    const rulesRef = reactive({
      email: [
        { required: true, type: 'email', message: '请输入邮箱地址', trigger: ['change', 'blur'] },
      ],
      password: [
        { required: true, message: '至少6位密码，区分大小写' },
        {
          validator: (rule: any, value: any) => {
            return new Promise((resolve, reject) => {
              let level = 0;

              // 判断这个字符串中有没有数字
              if (/[0-9]/.test(value)) {
                level++;
              }
              // 判断字符串中有没有字母
              if (/[a-zA-Z]/.test(value)) {
                level++;
              }
              // 判断字符串中有没有特殊符号
              if (/[^0-9a-zA-Z_]/.test(value)) {
                level++;
              }
              state.passwordLevel = level;
              state.percent = level * 30;
              if (level >= 2) {
                if (level >= 3) {
                  state.percent = 100;
                }
                resolve(null);
              } else {
                if (level === 0) {
                  state.percent = 10;
                }
                reject(new Error('密码强度不够'));
              }
            });
          },
        },
      ],
      password2: [
        { required: true, message: '至少6位密码，区分大小写' },
        {
          validator: (rule: any, value: string) => {
            return new Promise((resolve, reject) => {
              const password = modelRef.password;
              if (value === undefined) {
                reject(new Error('请输入密码'));
              }
              if (value && password && value.trim() !== password.trim()) {
                reject(new Error('两次密码不一致'));
              }
              resolve(null);
            });
          },
        },
      ],
      mobile: [{ required: true, message: '请输入正确的手机号', pattern: /^1[3456789]\d{9}$/ }],
      captcha: [{ required: true, message: '请输入验证码' }],
    });
    const { validateInfos, validate } = useForm(modelRef, rulesRef);

    const requestFailed = (err: AxiosError) => {
      state.registerBtn = true;
      notification.error({
        message: '错误',
        description: ((err.response || {}).data || {}).errorMessage || '请求出现错误，请稍后再试',
        duration: 4,
      });
    };
    const handlePasswordInputClick = () => {
      state.passwordLevelChecked = true;
    };

    const getCaptcha = () => {
      validate('mobile').then(values => {
        state.smsSendBtn = true;
        const interval = window.setInterval(() => {
          if (state.time-- <= 0) {
            state.time = 60;
            state.smsSendBtn = false;
            window.clearInterval(interval);
          }
        }, 1000);

        const hide = message.loading('验证码发送中..', 0);

        getSmsCaptcha({ mobile: values.mobile })
          .then(res => {
            setTimeout(hide, 2500);
            notification.success({
              message: '提示',
              description: '验证码获取成功，您的验证码为：' + res.captcha,
              duration: 8,
            });
          })
          .catch(err => {
            setTimeout(hide, 1);
            clearInterval(interval);
            state.time = 60;
            state.smsSendBtn = false;
            requestFailed(err);
          });
      });
    };

    const handleSubmit = (e: Event) => {
      state.registerBtn = true;
      e.preventDefault();
      validate()
        .then(values => {
          postRegister(values)
            .then(res => {
              state.registerBtn = false;
              message.info('注册成功');
              router.push({
                name: 'register-result',
                params: {
                  email: res.data.email,
                },
              });
            })
            .catch(err => {
              requestFailed(err);
              state.registerBtn = false;
            });
        })
        .catch(err => {
          console.warn('err', err);
          state.registerBtn = false;
        });
    };
    return {
      state,
      modelRef,
      validateInfos,
      handleSubmit,
      getCaptcha,
      handlePasswordInputClick,

      passwordLevelClass: computed(() => levelClass[state.passwordLevel]),
      passwordLevelName: computed(() => levelNames[state.passwordLevel]),
      passwordLevelColor: computed(() => levelColor[state.passwordLevel]),
    };
  },
  components: {
    MailOutlined,
  },
});
